<template>
    <div class="transition-all duration-500" v-bind:class="{
        'visible opacity-100' : active,
        'invisible opacity-25' : !active,
    }">
        <slot name="label" v-if="label">
            <label class="block mb-2">
                {{label}}
                <span class="inline-block text-gray-400" v-if="required">*</span>
            </label>
        </slot>
        <div class="flex items-center gap-2">
            <ElementsButtonGroup class="overflow-scroll md:overflow-hidden"
                v-bind:class="{ 'border border-red-700 dark:border-red-400 shadow dark:shadow-orange-800' : error.show }">
                <template v-for="o, i in options?.filter(item => item?.active === undefined || item?.active == true )" :key="o.value">
                    <ElementsButton color="white" isSquare :icon="modelValue == o.value ? 'circle-full' : 'circle'" :toggled="modelValue == o.value" @click="toggleVaule(o.value)"
                        class="userdata transition-all duration-300" v-bind:class="{
                            'opacity-0': !active,
                            '-translate-x-5': !active && i == 0,
                            'translate-x-5': !active && i == options.length - 1,
                            'translate-y-5': !active && i != options.length - 1 && i != 0,
                        }" noBold>
                        {{ o.label }}
                    </ElementsButton>
                </template>
            </ElementsButtonGroup>
            <LazyElementsHelpBubble v-if="path" :path="path" />
        </div>
    </div>
</template>
<script setup>
const props = defineProps({
    label: { type: String },
    modelValue: { default: null },
    required: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    options: { type: Array },
    path: { type: String, default: ''},
    validation: { type: Object, default: { autovalidate: () => {}, add: () => {}, remove: () => {} } },
})
const emit = defineEmits(['update:modelValue', 'error'])

const error = reactive({
    show: false,
    message: '',
})
const requiredValidation = () => useHandleValidation(props.options.find(o => o.value == props.modelValue), 'Dieses Feld ist erforderlich', error, emit)

const toggleVaule = (value) => {
    if(props.readonly) return
    emit('update:modelValue', value)
    props.validation.autovalidate()
}
const active = ref(false)
onMounted(function(){
    setTimeout(function(){ active.value = true }, 100)
    if(props.required) props.validation.add(props.path, requiredValidation)
})
onBeforeUnmount(() => active.value = false )
onUnmounted(function(){
    if(props.validation) props.validation.remove(props.path)
})
</script>